<template lang="pug">
  .course-day__tabs
    .tabs__wrapper
      v-tabs(v-model="activeTab" background-color="#fff" show-arrows)
        v-tab(
          v-for="tab in tabs"
          :key="tab.name"
          @click="switchTab(tab.name)"
          :disabled="disabled"
        )
          div.tab
            span.tab__label {{ tab.label }}
            div.tab__count
              span {{ tab.count }}
    slot(name="tabs-slot")
</template>

<script>
import { COURSE_DAY_PAGES } from '../core/courseDay-const'

export default {
  name: 'CourseDayTabs',

  props: {
    counts: Object,
    disabled: Boolean,
  },

  data: () => ({
    activeTab: 0
  }),

  computed: {
    tabs() {
      return [
        {
          name: COURSE_DAY_PAGES.ALL_WILL_COME,
          label: "Course List",
          count: this.counts.allWillCome
        },
        {
          name: COURSE_DAY_PAGES.ALL_WILL_NOT_COME,
          label: "Won't Come",
          count: this.counts.allWillNotCome
        }
      ]
    }
  },

  created() {
    this.setTabByQuery()
  },

  methods: {
    async switchTab(name) {
      if (this.$route.query.page_name === name) return
      await this.$router.replace({
        query: {
          ...this.$route.query,
          page_name: name,
          page: 1
        }
      })
      this.$emit('changed')
    },

    setTabByQuery() {
      let name = this.$route.query.page_name
      let tabIndex = this.tabs.findIndex(tab => tab.name === name)
      if (tabIndex < 0) return
      this.activeTab = tabIndex
    }
  },

  watch: {
    '$route.query.page_name': function (newVal, oldVal) {
      this.setTabByQuery()
    }
  }
}
</script>

<style lang="scss">
.course-day__tabs {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  @include media("<=phone") {
    flex-direction: column-reverse;
  }

  .tabs__wrapper {
    display: flex;
    flex-direction: column;

    @include media("<=phone") {
      border-top: 1px solid $border-color;
      width: 100%;
      margin: 0 auto;
      align-items: center;
      justify-content: center;
      min-height: 40px;
    }
  }

  .tab {
    display: flex;
    flex-direction: row;
    align-items: center;

    &__label {
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      margin-right: 10px;
    }

    &__count {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 20px;
      padding: 3px 10px;
      border-radius: 5px;
      background-color: $layout-bg;
      transition: background-color 0.15s ease-in-out;

      span {
        font-family: $font-roboto;
        font-size: 12px;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        text-align: center;
        color: $label-color;
      }
    }
  }

  .v-tab--active {
    .tab__count {
      transition: background-color 0.15s ease-in-out;
      background-color: $primary-color;

      span {
        color: #FFFFFF;
      }
    }
  }

  .v-slide-group__prev {
    display: none !important;
  }

  @include media("<=tablet") {
    .v-tabs-bar {
      height: initial;
    }
    .v-tab {
      padding: 10px;

      .tab__label {
        font-size: 12px;
      }

      .tab__count {
        padding: 3px 5px;
      }
    }
    .cd-body__pagination {
      padding: 5px;
      margin-left: initial !important;
    }
  }

  @include media("<=phone") {
    .v-slide-group__content {
      width: 100%;
    }
    .v-tab {
      width: 100%;
    }
    .pagination__per-page .app-input__before {
      font-size: 10px;
    }
  }
}
</style>
